.redo-matching {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .redo-matching h2 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .redo-matching p {
    font-size: 16px;
    color: #444;
    margin-bottom: 20px;
  }
  
  .redo-button {
    padding: 10px 20px;
    background-color: #E578FF;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .redo-button:hover {
    background-color: #FBECFF;
  }
  