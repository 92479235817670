.preferences-settings {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    font-family: Arial, sans-serif;
  }
  
  .preferences-settings h2 {
    text-align: center;
    margin-bottom: 15px;
    color: #333;
  }
  
  .preferences-settings p {
    font-size: 16px;
    color: #444;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .preferences-settings button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #E578FF;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .preferences-settings button:hover {
    background-color: #FBECFF;
  }
  