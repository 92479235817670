.settings-page {
    display: flex;
}

.side-menu {
    width: 250px;
    flex-shrink: 0;
}

.settings-content {
    flex: 1;
    padding: 20px; 
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.settings-title {
    width: 100%;
    text-align: center;
}

/* Tabs Container */
.settings-tabs {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
    width: 100%;
    justify-content: center;
}

/* Individual Tab/Card */
.settings-tabs .tab {
    flex: 1;
    max-width: 200px;
    padding: 20px;
    background-color: #F6D2FF; /* default tab background */
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.settings-tabs .tab:hover {
    transform: translateY(-2px);
}

.settings-tabs .tab.active {
    background-color: #E578FF; /* highlight for selected tab */
}

/* Tab Title and Description */
.settings-tabs .tab .tab-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.settings-tabs .tab .tab-description {
    font-size: 14px;
    color: #555;
}

/* Original Buttons (if needed elsewhere) */
.buttons-container {
    display: flex;
    gap: 30px;
    justify-content: center; 
    margin-top: 20px;
    margin-bottom: 20px;
}

.toggle, .primary-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    transition: background-color 0.3s ease;
}

.toggle {
    background-color: #f0f0f0;
}

.primary-button {
    background-color: #007bff;
    color: white;
}

.primary-button:hover {
    background-color: #0056b3;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .settings-page {
        flex-direction: column;
    }
    .settings-content {
        padding-left: 0;
    }
    .settings-tabs {
        flex-direction: column;
        gap: 10px;
    }
}