/* loading screen styling */

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-screen p {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #333;
}

.spinner {
  margin-top: 20px;
  width: 48px;
  height: 48px;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #E578FF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.couple-dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    font-family: 'Syne', sans-serif;
  }
  
  .dashboard-content {
    flex: 1;
    padding-left: 100px;
  }
  
  .couple-dashboard h1 {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .expandable-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
  }

  .expandable-cards-container > * {
    width: 100%;
    max-width: 2500px;
  }


  @media screen and (max-width: 768px) {
    .dashboard-content {
        padding-left: 0px; 
    }
}
 