.preferences-page-container {
  font-family: 'Raleway', sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  background-color: #fff;
}

.page-title {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

/* Preferences Form */
.preferences-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.preference-group {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
}

.preference-group legend {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.preference-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  cursor: pointer;
}

.preference-group input[type="radio"] {
  margin-right: 8px;
}

.preference-group select {
  padding: 8px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Next Step Button */
.next-step-container {
  text-align: center;
}

.next-step-button {
  padding: 12px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #E578FF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-step-button:hover {
  background-color: #FBECFF;
  color: #E578FF;
}

/* Loading Spinner */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #E578FF;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Match Tabs (if you use them elsewhere) */
.match-tabs {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}

.match-tab {
  padding: 5px 10px;
  border: none;
  background-color: #ddd;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9rem;
}

.match-tab.active {
  background-color: #E578FF;
  color: #fff;
}

/* 
  =========================
  VENUE CARDS STYLING
  =========================
*/

/* Increase container max-width for a desktop grid (adjust as needed) */
.preferences-page-container {
  font-family: 'Raleway', sans-serif;
  padding: 20px;
  max-width: 1200px; /* increased from 800px */
  margin: 0 auto;
  background-color: #fff;
}

/* Grid container for venue cards */
.preferences-page-container .venues-bucket {
  display: grid;
  grid-template-columns: repeat(3, 1fr);  /* Force 3 columns */
  gap: 20px;
  margin-top: 20px;
  /* Optional: Center the grid if desired */
  justify-items: center;
}

/* Each card: make sure it fills its grid cell */
.preferences-page-container .venue-card {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

/* Uniform image dimensions */
.preferences-page-container .venue-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

/* Title: force a single line that spans the full card width */
.preferences-page-container .venue-title {
  font-size: 1.2rem;
  margin: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

/* Match description text */
.preferences-page-container .match-description {
  margin: 0 10px 10px;
  font-size: 0.9rem;
  color: #555;
}

/* Scrollable horizontal container for date chips */
.preferences-page-container .match-dates-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  gap: 8px;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #E578FF #f3f3f3;
}

/* Date chips */
.preferences-page-container .match-date {
  flex: 0 0 auto;
  min-width: 70px;
  padding: 6px 12px;
  background-color: #ddd;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.85rem;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.preferences-page-container .match-date:hover {
  background-color: #E578FF;
  color: #fff;
}

.preferences-page-container .match-date.active {
  background-color: #E578FF;
  color: #fff;
}

/* Custom scrollbar for WebKit browsers */
.preferences-page-container .match-dates-container::-webkit-scrollbar {
  height: 6px;
}

.preferences-page-container .match-dates-container::-webkit-scrollbar-thumb {
  background-color: #E578FF;
  border-radius: 3px;
}

.preferences-page-container .match-dates-container::-webkit-scrollbar-track {
  background: #f3f3f3;
}

