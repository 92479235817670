.billing-settings {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.billing-settings h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.billing-item {
  margin-bottom: 15px;
}

.billing-settings label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
}

.billing-settings p {
  font-size: 16px;
  color: #444;
  margin: 0;
}

.billing-actions {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.billing-settings button {
  flex: 1;
  padding: 10px;
  background-color: #E578FF; /* Main brand color */
  border: none;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.billing-settings button:hover {
  background-color: #d16ce6; /* Slightly darker shade for hover */
}

.billing-settings button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

/* Invoice history table styling */
.invoice-history {
  margin-top: 20px;
}

.invoice-history h3 {
  margin-bottom: 10px;
  color: #555;
}

.invoice-history table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.invoice-history table th,
.invoice-history table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.invoice-history table th {
  background-color: #f3f3f3;
  color: #333;
  font-weight: 600;
}

.invoice-history table td a {
  color: #E578FF;
  text-decoration: none;
}

.invoice-history table td a:hover {
  text-decoration: underline;
}
