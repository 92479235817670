.expandable-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease;
  margin-bottom: 20px;
}

.expandable-card:hover {
  transform: translateY(-2px);
}

.card-header {
  background-color: #f9f0ff;
  padding: 16px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #e578ff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-content h3 {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
}

.header-content p {
  margin: 4px 0 0;
  font-size: 1rem;
  color: #555;
}

.toggle-button {
  margin-top: 12px;
  background-color: #e578ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}

.toggle-button:hover {
  background-color: #d66acb; 
}

.card-body {
  padding: 16px;
}

.date-picker-container {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.date-picker-container label {
  color: #e578ff;
  font-weight: bold;
  margin-right: 8px;
}

.date-picker-container input[type="date"] {
  padding: 8px;
  border: 1px solid #e578ff;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.2s ease;
}

.date-picker-container input[type="date"]:focus {
  border-color: #d66acb;
}

.match-dates-container {
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding: 8px 0;
}

.match-tab {
  flex: 0 0 auto;
  padding: 6px 12px;
  border: none;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.match-tab:hover,
.match-tab.active {
  background-color: #007BFF;
  color: #fff;
}
