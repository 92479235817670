.venue-management {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .venue-management h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  