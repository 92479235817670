.distance-page-container {
  font-family: 'Raleway', sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.distance-controls {
  padding-top: 60px;
}

.custom-location-control {
  padding-top: 10px;
  padding-bottom: 30px;
}

.distance-controls,
.custom-location-control {
  text-align: center;
}

.distance-controls label,
.custom-location-control label {
  font-size: 16px;
  margin-right: 10px;
}

.distance-controls select,
.custom-location-control input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #E578FF;
  border-radius: 8px;
  outline: none;
}

.custom-location-control button {
  padding: 8px 12px;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  background-color: #E578FF;
  color: #fff;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.custom-location-control button:hover {
  background-color: #FBECFF;
  color: #E578FF;
}

.map-container {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 8px;
}

/* Tooltip styling */
.tooltip-content {
  font-size: 14px;
}

/* Selected Venues Bucket */
.selected-venues-bucket {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.selected-venues-bucket h3 {
  text-align: center;
  margin-bottom: 10px;
}

.selected-venues-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.selected-venue-item {
  width: 150px;
  text-align: center;
}

.selected-venue-image {
  width: 100%;
  height: 100px;
  border-radius: 4px;
}

.next-step-container {
  text-align: center;
  margin-top: 20px;
}

.next-step-button {
  padding: 12px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #E578FF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-step-button:hover {
  background-color: #FBECFF;
  color: #E578FF;
}